import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Divider, Button, theme, Input } from 'antd';
import { useSelector } from 'react-redux';
import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/logo-icon.png';
import logoText from '@/style/images/logo-text.png';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardTwoTone,
  MessageTwoTone,
  ReconciliationTwoTone,
  BugTwoTone,
  ProjectTwoTone,
  MenuOutlined,
  ProfileTwoTone,
  ContactsTwoTone,
  DollarTwoTone,
} from '@ant-design/icons';
import { selectCurrentAdmin } from '@/redux/auth/selectors';
import useMediaQuery from '@/hooks/useMediaQuery/useMediaQuery';
import './Navigation.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  const currentAdmin = useSelector(selectCurrentAdmin);
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setCollapsed(isMobile || isNavMenuClose);
  }, [isNavMenuClose, isMobile]);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className={`mobile-toggle-button ${isMobile ? '' : 'hidden'}`}>
        <Button
          type="text"
          icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
          onClick={onCollapse}
          className="mobile-collapse-button"
        />
      </div>

      {!isMobile || !collapsed ? (
        <Sider
          collapsible
          trigger={null}
          collapsed={collapsed}
          onCollapse={onCollapse}
          className={`navigation custom-sider ${isMobile && !collapsed ? 'expanded' : ''}`}
        >
          <div className="logo">
            <img
              src={logoIcon}
              alt="Logo"
              className={collapsed ? 'logo-icon' : 'logo-icon-expanded'}
            />
            {!collapsed && <img src={logoText} alt="Logo" className="logo-text" />}
          </div>

          <Button
            type="text"
            icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
            onClick={onCollapse}
            className="collapse-button"
          />

          {!collapsed && (
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <img
                src="https://i.ibb.co/M1JfKsS/employee.gif"
                alt="User Avatar"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              />
              <p>{`${currentAdmin.name} ${currentAdmin.surname}`}</p>
              <p>Employee</p>
            </div>
          )}

          {!collapsed && (
            <div>
              <Input.Search
                placeholder="Search..."
                onSearch={(value) => console.log(value)}
                enterButton
              />
            </div>
          )}

          <Menu mode="inline">
            {!collapsed && (
              <Menu.Item key={'DashboardHeading'} disabled>
                <span className="sidebar-heading">Dashboard</span>
              </Menu.Item>
            )}

            <Divider />
            <Menu.Item key={'Dashboard'} icon={<DashboardTwoTone />}>
              <Link to={'/'}>Dashboard</Link>
            </Menu.Item>

            <Menu.Item key={'Invoice'} icon={<MessageTwoTone />}>
              <Link to={'/invoice'}>Inbox</Link>
            </Menu.Item>

            {!collapsed && (
              <Menu.Item key={'Divider'} disabled>
                <span className="sidebar-heading">Apps</span>
              </Menu.Item>
            )}

            <Divider />
            <SubMenu
              key={'Marketing Tools'}
              icon={<ReconciliationTwoTone />}
              title={'Marketing Tools'}
            >
              <Menu.Item key={'TimeInRecords'}>
                <Link to={'/colorpicker'}>Color Picker</Link>
              </Menu.Item>

              <Menu.Item key={'MetaLengthChecker'}>
                <Link to={'/meta-length-checker'}>Meta Length Checker</Link>
              </Menu.Item>

              <Menu.Item key={'AITextDetector'}>
                <Link to={'/ai-text-detector'}>AI Text Detector</Link>
              </Menu.Item>

              <Menu.Item key={'PageSpeedInsights'}>
                <Link to={'/page-speed-insights'}>Page Speed Insights</Link>
              </Menu.Item>
              <Menu.Item key={'speechToText'}>
                <Link to={'/voice-over'}>Text to Speech</Link>
              </Menu.Item>
            </SubMenu>

            {!collapsed && (
              <Menu.Item key={'Divider'} disabled>
                <span className="sidebar-heading">Forms</span>
              </Menu.Item>
            )}

            <Divider />

            {/* <Menu.Item key={'Complaint'} icon={<BugTwoTone />}>
              <Link to={'/complaint'}>Complaint Form</Link>
            </Menu.Item> */}

            <SubMenu
              key={'Feedbacks and Requests'}
              icon={<ProjectTwoTone />}
              title={'Feedback & Request'}
            >
              <Menu.Item key={'LeavesForm'}>
                <Link to={'/leaves'}>Leave Request</Link>
              </Menu.Item>
              <Menu.Item key={'SuggestionsComplaintForm'}>
                <Link to={'/suggestions&complaints'}>Suggestion/Complaint</Link>
              </Menu.Item>
            </SubMenu>

            {currentAdmin?._id === '6591d57a913b9f2caaedbcd5' && (
              <SubMenu key={'Reports'} icon={<ReconciliationTwoTone />} title={'Reports'}>
                <Menu.Item key={'AttendanceReport'}>
                  <Link to={'/daily-activity-log'}>Daily Activity Log</Link>
                </Menu.Item>

                <Menu.Item key={'TimeInRecords'}>
                  <Link to={'/time-in-records'}>Time-in Records</Link>
                </Menu.Item>

                <Menu.Item key={'MonthlySummary'}>
                  <Link to={'/attendance-report'}>Attendance Report</Link>
                </Menu.Item>

                <Menu.Item key={'ExtraHours'}>
                  <Link to={'/extra-hours-logs'}>Extra Hours Logs</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {currentAdmin?._id === '6591d57a913b9f2caaedbcd5' && (
              <>
                <Menu.Item key={'HRHeading'} disabled>
                  <span className="sidebar-heading">Human Resources</span>
                </Menu.Item>

                <Menu.Item key={'Recruitment'} icon={<ContactsTwoTone />}>
                  <Link to={'/recruitment'}>Employee Recruitment</Link>
                </Menu.Item>

                <Menu.Item key={'Employees'} icon={<ProfileTwoTone />}>
                  <Link to={'/employees'}>Employee Details</Link>
                </Menu.Item>

                <Menu.Item key={'SalarySlipForm'} icon={<DollarTwoTone />}>
                  <Link to={'/salary-slip'}>Salary Management</Link>
                </Menu.Item>
              </>
            )}
          </Menu>
        </Sider>
      ) : null}
    </>
  );
}
